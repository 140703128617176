<template>
  <CardContainer
    v-slot="slotProps"
    :impose-appearance="'List'"
    :collapse-button="props.collapseButton"
    class="gradient m-auto"
  >
    <TopRightButton
      v-if="$route.name !== 'Checking'"
      @click="checkingSetup.goToCheckPage()"
    />

    <CheckingAppointment
      :col="12"
      :checking="checkingSetup.checking"
      :contraction="true"
    />

    <MDBCol
      col="8"
      class="inside-block d-flex flex-column p-0"
    >
      <Address
        :col="12"
        :address="checkingSetup.checking.Accomodation.Address"
        class="flex-grow-1"
      />

      <RoomsToCheck
        :col="12"
        :checking="checkingSetup.checking"
      />

      <MDBRow
        v-if="false"
        class="d-flex flex-row w-100 h-100 m-0"
      >
        <Owners
          :col="6"
          :checking="checkingSetup.checking"
        />

        <Occupants
          :col="6"
          :checking="checkingSetup.checking"
        />        
      </MDBRow>
    </MDBCol>

    <Image
      :col="4"
      :photo-id="checkingSetup.checking.Accomodation.MainPhoto.Id"
    />

    <MDBCollapse
      v-if="false"
      :id="slotProps.collapseId"
      v-model="slotProps.cardCollapse"
      class="inside-block"
    >
      <MDBRow
        class="d-flex flex-row w-100 h-100 m-0"
      >
        <Experts
          :col="6"
          :checking="checkingSetup.checking"
        />

        <Representatives
          :col="6"
          :checking="checkingSetup.checking"
        />
      </MDBRow>
    </MDBCollapse>
  </CardContainer>
</template>

<script setup>
import {
  MDBCol,
  MDBCollapse,
  MDBRow,
} from "mdb-vue-ui-kit";

import Address from '@/components/summary/common/Address';
import CheckingAppointment from '@/components/summary/common/CheckingAppointment';
import Experts from '@/components/summary/common/Experts';
import Occupants from '@/components/summary/common/Occupants';
import Owners from '@/components/summary/common/Owners';
import Image from '@/components/summary/common/Image';
import Representatives from '@/components/summary/common/Representatives';
import RoomsToCheck from '@/components/summary/common/RoomsToCheck';
import TopRightButton from '@/components/summary/common/TopRightButton';

import { defineProps, inject } from 'vue'

const props = defineProps({
  collapseButton: {
    type: Boolean,
    default: true,
  },
});

const checkingSetup = inject('checkingSetup')
</script>

import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default function Setup() {
  const store = useStore();

  const loaded = ref(false);

  const checkingsList = computed(() => {
    return store.state.checkingsList;
  });
  const nextCheckingsList = computed(() => {
    return store.getters.nextCheckingsList;
  });
  
  const filter = (elem) => {
    // store.state.app.filterList.findIndex((element) => element.Id == accommodation.Id
    if (!store.state.app.search || elem.Accomodation.Address.FullAddress.toLowerCase().indexOf(store.state.app.search.toLowerCase()) > 0) {
      return true
    }
    else return false
  };

  onMounted(() => {
    store.dispatch("getAllCheckings")
    .then(() => loaded.value = true)
  });

  return {
    checkingsList,
    nextCheckingsList,
    filter,
    loaded
  };
}

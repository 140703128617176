import { provide } from "vue";
import { useRouter } from "vue-router";
import { mutateKey } from '@/helpers'

export default function Setup(checking) {
  const router = useRouter();

  const goToCheckPage = () => {
    mutateKey('checkingChosen', checking);
    router.push({ name: 'Checking', params: { checkingId: checking.Id }})
  }
  const checkingSetup = {
    checking,
    goToCheckPage
  };
  provide('checkingSetup', checkingSetup);

  return {
    checkingSetup
  }
}

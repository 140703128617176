<template>
  <CardContainer
    v-slot="slotProps"
    :impose-appearance="'Card'"
    :collapse-button="props.collapseButton"
    class="gradient m-auto"
  >
    <TopRightButton
      v-if="$route.name !== 'Checking'"
      @click="checkingSetup.goToCheckPage()"
    />

    <CheckingAppointment
      :col="12"
      :checking="checkingSetup.checking"
      :contraction="true"
    />

    <Address
      :col="12"
      :address="checkingSetup.checking.Accomodation.Address"
    />

    <Image
      :col="12"
      :photo-id="checkingSetup.checking.Accomodation.MainPhoto.Id"
    />

    <RoomsToCheck
      :col="12"
      :checking="checkingSetup.checking"
    />

    <MDBCollapse
      v-if="false"
      :id="slotProps.collapseId"
      v-model="slotProps.cardCollapse"
      class="p-0"
    >
      <Owners
        :col="12"
        :checking="checkingSetup.checking"
      />

      <Occupants
        :col="12"
        :checking="checkingSetup.checking"
      />

      <Experts
        :col="12"
        :checking="checkingSetup.checking"
      />

      <Representatives
        :col="12"
        :checking="checkingSetup.checking"
      />
    </MDBCollapse>
  </CardContainer>
</template>

<script setup>
import {
  MDBCollapse,
} from "mdb-vue-ui-kit";

import Address from '@/components/summary/common/Address';
import CheckingAppointment from '@/components/summary/common/CheckingAppointment';
import Experts from '@/components/summary/common/Experts';
import Occupants from '@/components/summary/common/Occupants';
import Owners from '@/components/summary/common/Owners';
import Image from '@/components/summary/common/Image';
import Representatives from '@/components/summary/common/Representatives';
import RoomsToCheck from '@/components/summary/common/RoomsToCheck';
import TopRightButton from '@/components/summary/common/TopRightButton';

import { defineProps, inject } from 'vue'

const props = defineProps({
  collapseButton: {
    type: Boolean,
    default: true,
  },
});

const checkingSetup = inject('checkingSetup')
</script>

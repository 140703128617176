<template>
  <div class="pt-4 pt-md-3">
    <div v-if="$route.name == 'CheckingsList'">
      <div
        v-if="loaded"
      >
        <div
          v-if="nextCheckingsList.length !== 0"
          class="mx-auto mt-5 w-fit fst-italic"
        >
          {{ $tcust('appointment') }}
        </div>

        <div v-else>
          <DashBoard>
            <div
              v-for="checking of checkingsList"
              :key="checking.Id"
              class="p-0 m-3"
              :class="$store.state.app.listAppearance === 'List' ? 'w-100' : ''"
            >
              <Checking
                v-show="filter(checking)"
                :checking="checking"
                :collapseButton="false"
              />
            </div>
          </DashBoard>
        </div>
      </div>

      <Loader v-else-if="!loaded" />      
    </div>

    <router-view v-else />
  </div>
</template>

<script setup>
import Checking from "@/components/summary/checking/Checking";

import Setup from "./CheckingsListSetup";
const { checkingsList, filter, loaded, nextCheckingsList } = Setup();
</script>
